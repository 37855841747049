import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { BarChart } from "../tools/Chart";

const Dashboard = (props) => {
  const [OrderStats, setOrderStats] = useState({});
  const [usersStats, setUsersStats] = useState({});
  const [revenueStats, setRevenueStats] = useState({});
  const [invoiceStats, setInvoiceStats] = useState({});

  // dashboard stats
  const getOrdersStat = async () => {
    await axios
      .get(server + "/api/v1/dashboard/orders-stats", config)
      .then((res) => {
        setOrderStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsersStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/users", config)
      .then((res) => {
        setUsersStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // barchart data
  const getOrdersBarChart = async () => {
    await axios
      .get(server + "/api/v1/dashboard/revenue-graph", config)
      .then((res) => {
        const e = document.getElementById("order_chart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        BarChart(canvas, res.data, "blue", "Orders");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOrdersBarStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/revenue-stats", config)
      .then((res) => {
        setRevenueStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInvoiceStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/deposit-stats", config)
      .then((res) => {
        setInvoiceStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // call all the functions at once
  // const callAll = async () => {
  //   // promise all
  //   await Promise.all([getOrdersStat(), getUsersStats(), getOrdersBarChart()]);
  // };

  useEffect(() => {
    getOrdersStat();
    getUsersStats();
    getOrdersBarChart();
    getOrdersBarStats();
    getInvoiceStats();
  }, []);

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Dashboard</h3>
                  </div>
                  {/* <div className="d-flex">
                    <div className="form-group mr-2">
                      <label htmlFor="">From Date</label>
                      <input
                        type="date"
                        placeholder="From Date"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        placeholder="From Date"
                        className="form-control"
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Orders</h5>
                            <h6 className="py-2">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-box-view-fill"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Users</h5>
                            <h6 className="py-2">
                              {usersStats.allTimeUsersCount
                                ? usersStats.allTimeUsersCount
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-users"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Revenue</h5>
                            <h6 className="py-2">
                              {revenueStats.allTimeRevenue
                                ? revenueStats.allTimeRevenue
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-sign-usd"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Deposits</h5>
                            <h6 className="py-2">
                              {invoiceStats.allTimeInvoicesAmount
                                ?.allTimeInvoicesAmount
                                ? invoiceStats.allTimeInvoicesAmount.allTimeInvoicesAmount.toFixed(
                                    2
                                  )
                                : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block ">
                <div className="card" style={{ borderRadius: "0.8rem" }}>
                  <div className="card-inner">
                    <div className="d-flex flex-wrap">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Orders:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0 d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {OrderStats.todayOrdersCount
                                      ? OrderStats.todayOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {OrderStats.thisWeekOrdersCount
                                      ? OrderStats.thisWeekOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {OrderStats.thisMonthOrdersCount
                                      ? OrderStats.thisMonthOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-5">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Users:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0 d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {usersStats.todayOrdersCount
                                      ? usersStats.todayOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {usersStats.thisWeekUsersCount
                                      ? usersStats.thisWeekUsersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {usersStats.thisMonthUsersCount
                                      ? usersStats.thisMonthUsersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-5">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Revenue:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0  d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {revenueStats.todayRevenue
                                      ? revenueStats.todayRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {revenueStats.thisWeekRevenue
                                      ? revenueStats.thisWeekRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {revenueStats.thisMonthRevenue
                                      ? revenueStats.thisMonthRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-5">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Deposits:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0  d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {invoiceStats.todayInvoicesAmount
                                      ?.todayInvoicesAmount
                                      ? invoiceStats.todayInvoicesAmount?.todayInvoicesAmount.toFixed(
                                          2
                                        )
                                      : 0}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {" "}
                                    {invoiceStats.thisWeekInvoicesAmount
                                      ?.thisWeekInvoicesAmount
                                      ? invoiceStats.thisWeekInvoicesAmount?.thisWeekInvoicesAmount.toFixed(
                                          2
                                        )
                                      : 0}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {" "}
                                    {invoiceStats.thisMonthInvoicesAmount
                                      ?.thisMonthInvoicesAmount
                                      ? invoiceStats.thisMonthInvoicesAmount?.thisMonthInvoicesAmount.toFixed(
                                          2
                                        )
                                      : 0}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="card card-bordered h-100">
                      <div className="card-header">
                        <h5 className="card-title">Orders Graph</h5>
                      </div>
                      <div className="card-body">
                        <div id="order_chart"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
