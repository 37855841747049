import React from "react";
import { useRoutes } from "react-router-dom";
import Dashboard from "../source/Dashboard";
import Orders from "../source/Orders";
import Users from "../source/Users";
import Settings from "../source/Settings";
import Pricing from "../source/Pricing";
import USPSTracking from "../source/USPSTracking";
import SingleType from "../source/Components/SingleType";
import Staff from "../source/Staff";
import CustomPricing from "../source/Components/CustomPricing";
import { useAuthStore } from "../stores/AuthStore";
import BulkOrders from "../source/BulkOrders";
import Logs from "../source/Logs";
import Website from "../source/Website";
import Invoices from "../source/Invoices";

const DashboardRoutes = (props) => {
  const { role } = useAuthStore();
  return useRoutes([
    { path: "", element: <Dashboard sidebarRef={props.sidebarRef} /> },
    {
      path: "/orders",
      element:
        role === "admin" ? (
          <Orders sidebarRef={props.sidebarRef} />
        ) : (
          <Dashboard sidebarRef={props.sidebarRef} />
        ),
    },

    {
      path: "/admin-settings",
      element: <Settings sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/users",
      element: <Users sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/users/:id",
      element: <CustomPricing sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/bulk-orders",
      element:
        role === "admin" ? (
          <BulkOrders sidebarRef={props.sidebarRef} />
        ) : (
          <Dashboard sidebarRef={props.sidebarRef} />
        ),
    },

    {
      path: "/pricing",
      element: <Pricing sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/pricing/:id",
      element: <SingleType sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/usps",
      element: <USPSTracking sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/staff",
      element:
        role === "admin" ? (
          <Staff sidebarRef={props.sidebarRef} />
        ) : (
          <Dashboard sidebarRef={props.sidebarRef} />
        ),
    },
    {
      path: "/logs",
      element:
        role === "admin" ? (
          <Logs sidebarRef={props.sidebarRef} />
        ) : (
          <Dashboard sidebarRef={props.sidebarRef} />
        ),
    },

    // {
    //   path: "/website",
    //   element: <Website sidebarRef={props.sidebarRef} />,
    // },

    {
      path: "/invoices",
      element: <Invoices sidebarRef={props.sidebarRef} />,
    },
  ]);
};

export default DashboardRoutes;
