import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { config, server } from "../env";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const Settings = () => {
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState("");
  const [cryptos, setCryptos] = useState([]);
  const [showAddLabel, setShowAddLabel] = useState(false);
  const onCloseAddLabelModal = () => {
    setShowAddLabel(false);
  };
  const onOpenAddLabelModal = () => setShowAddLabel(true);
  useEffect(() => {
    readCryptosList();
  }, []);
  const readCryptosList = async () => {
    await axios
      .get(server + "/v1/admin/crypto/read", config)
      .then((res) => {
        setCryptos(res.data.crypto);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createCryptoList = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const params = new FormData();
    params.append("name", e.target.name.value);
    params.append("address", e.target.address.value);
    params.append("ticker", e.target.ticker.value);
    params.append("confirmations", e.target.confirmations.value);
    params.append("color", e.target.color.value);
    params.append("image", e.target.logo.files[0]);
    await axios
      .post(server + "/v1/admin/crypto/create", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readCryptosList();
        onCloseAddLabelModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const deleteCrypto = async (id) => {
    setLoader(<Loader />);
    await axios
      .delete(server + "/v1/admin/crypto/delete/" + id, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readCryptosList();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="content container-fluid">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">Crypto</h5>

              <button
                className="btn btn-primary"
                onClick={() => onOpenAddLabelModal()}
              >
                Add Crypto
              </button>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Ticker</th>\<th scope="col">Address</th>
                    <th scope="col">Confirmations</th>
                    <th scope="col">Color</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cryptos.map((crypto, index) => (
                    <tr key={index}>
                      <td>{crypto.name}</td>
                      <td>{crypto.ticker}</td>
                      <td>
                        {crypto?.address?.length > 50
                          ? crypto?.address?.slice(0, 49)
                          : crypto.address}
                      </td>
                      <td>{crypto.confirmations}</td>
                      <td>{crypto?.color}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => deleteCrypto(crypto._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showAddLabel}
        onHide={onCloseAddLabelModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Add Label Type </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-validate is-alter" onSubmit={createCryptoList}>
            <div className="form-group mb-3">
              <label className="form-label">Name</label>

              <input
                type="text"
                name="name"
                placeholder="Enter Name"
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label className="form-label">Ticker</label>

              <input
                type="text"
                name="ticker"
                placeholder="Enter Coin Name"
                className="form-control"
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label">Confirmations</label>

              <input
                type="Number"
                name="confirmations"
                placeholder="Enter Confirmations"
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label className="form-label">Address</label>

              <input
                type="text"
                name="address"
                placeholder="Enter Address"
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <label className="form-label">Color</label>

              <input
                type="color"
                name="color"
                placeholder="Enter Color"
                className="form-control"
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label">Coin Logo</label>

              <input
                type="file"
                name="logo"
                id="logo"
                className="form-control "
              />
            </div>

            <div className="d-flex justify-content-end">
              <button className="btn btn-lg btn-primary" type="submit">
                Create
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Settings;
